.contenedor-menu{
    display: inline-grid;
    grid-template-columns: 1fr auto auto auto auto auto auto auto;
    width: 100%;
    gap: 0 40px;
    align-items: center;
}
.contenedor-menu .active p:after{
    margin-top: 10px;
    content: "";
    display: flex;
    height: 3px;
    align-items: center;
    background:  #e89a51;
    bottom: 0;
    transition: 0.4s;
    width: 100% !important;
    background:  #e89a51;
    transition: 0.4s linear;
}
.contenedor-menu div{height: 50px;align-items: center;display: flex;cursor: pointer}
.contenedor-menu p:after,.contenedor-menu .active p:after{
    margin-top: 10px;
    content: "";
    display: flex;
    height: 3px;
    align-items: center;
    background:  #e89a51;
    bottom: 0;
    width: 0;
    transition: 0.4s;

}
.contenedor-menu div:hover p:after{
    width: 100%;
    transition: 0.4s linear;
    background:  #e89a51;

}
.cont-logo{width: 150px;height:49px;display: flex;justify-content: center;}
.cont-logo img{width: 100%;}

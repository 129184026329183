.header{
  position: fixed !important;
  width: 100% !important;
  z-index: 9;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.cont-login{
  width: 100%;
  height: 100vh;
  display: flex;
}
.cont-img{
  position: sticky;
  left: 0;
  width: 38vw;
  height: 100%;
  background: linear-gradient(to bottom, #2b7de1, #7e57c6 85%);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /*background: url("../src/assets/image/img-auth.jpg");*/
  /*background-size: cover;*/
}
.logo{
  background: url("../src/assets/image/logo.png");
  background-size: contain;
  width: 225px;
  height: 73px;
}
.cont-info-login{
  width: 62vw;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}
.info-login{
  display: grid;
  grid-gap: 15px;
  padding: 25px;
  box-shadow: 0 0px 6px rgb(0 0 0 / 20%);
  border-radius: 5px;
  width: 350px;
}
.titulo-form{
  font-weight: 600 !important;
  font-size: 16px !important;
}
.form-login{
  display: grid;
  gap: 15px;
}
/*.cont-cards{*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  position: fixed;*/
/*  margin: auto;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*}*/
.cont-cards{
  display: inline-grid;
  /* align-items: center; */
  /* justify-content: center; */
  position: relative;
  grid-template-columns: auto auto auto;
  margin: auto;
  width: 100%;
  height: 100%;
  gap: 15px;
  align-items: center;
  justify-content: center;
  top: 85px;
}
.cont-cards .MuiPaper-elevation{text-align: center;}
.cont-cards .MuiCard-root{;width: 285px;background: linear-gradient(to bottom, #2b7de1, #7e57c6 85%);color: #fff;height: 250px;
  align-items: center;
  justify-content: center;
  display: flex;border-radius: 25px;}
.cont-cards .MuiCardMedia-media{
  width: 100px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  padding: 25px 25px 0 25px;
  pointer-events: none;
}
.cont-cards .MuiButtonBase-root {height: 100%;}

.cont-taxi-plan{
  display: flex;
  width: 100%;
}
.aside-panel{
  width: 280px;
  height: 100vh;
  position: fixed;
  top: 0;
  box-shadow: 2px 0px 6px rgb(0 0 0 / 10%);
  z-index: 9;
  background: white;
  top: 64px;
}
.content-panel{
  width: auto;
  padding: 25px;
  margin-left: 280px;
  display: block !important;
  margin-top: 64px;
}
.w-100{width: 100%;}
.d-flex {
  display: inline-flex;
}
.pd-25{
  padding: 15px 25px 15px 0;
}
.d-flex button{margin: 0 15px;}
.d-flex h5{
  border-right: 1px solid rgba(0,0,0,0.1);
  padding-right: 15px;
}
/*.content-panel .sd{width: 100%;}*/
.css-1ndip6p{width: auto !important;}
.contTabs{display: grid;grid-template-columns: auto 1fr;width: 100%;}
.MuiAutocomplete-popper{width: auto !important;}
.btn-actions{
  margin: 5px !important;
  font-size: 10px !important;
}
.contVentasDirectas{
  
}
.contBTNVD button{font-size: 15px;}
.contBTNVD{
  display: grid !important;
    gap: 25px;
    justify-content: center;
    text-align: center;
}
.contBTNVD button, .contBTNVD label{width: 100%;margin: 0;}
.dGrid{
  display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
}
.grid-1{
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr;
}
.grid-2{
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr 1fr;
}
.grid-3{
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 15px 0;
  align-items: center;
}
.btn-atras{margin: 15px 15px 35px 15px !important;}
.TextBold{
  font-weight: 600 !important;
  margin-left: 5px !important;
  position: relative;
  color: #666;
}
.cont-txt-folio{
  color: #666;
  margin-bottom: 25px;
}
.B-bottom{
  padding-bottom: 25px;
  border-bottom: 1px solid #dfdfdf;
}
.m-bottom{margin-bottom: 15px !important;}
.mt-25{margin-top: 15px;}
.mb-25{margin-bottom: 15px;}
.cont-btn-add{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.cont-btn-add .count{
  padding: 3px 8px;
  background: #1976d2;
  border-radius: 5px;
  color: #fff;
  margin-right: 10px;
}
.aling-right{text-align: right;}
.border-bottom{
  padding-bottom: 25px;
  border-bottom: 1px solid #dfdfdf;
  margin-top: 25px;
}
.border-bottom button{margin-right: 10px}
.titulo-reportes{font-size: 15px !important;font-weight: 600 !important;}
.rangeReporte .DateInput_input{width: 110px !important;}
/*.cont-center{text-align: center;}*/
/*.credencial{*/
/*  background: #e52d27;  !* fallback for old browsers *!*/
/*  background: -webkit-linear-gradient(to right, #b31217, #e52d27);  !* Chrome 10-25, Safari 5.1-6 *!*/
/*  background: linear-gradient(to right, #b31217, #e52d27); !* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ *!*/
/*  width: 8.56cm;*/
/*  height: 5.398cm;*/
/*  position: relative;*/
/*  border-radius: 10px;*/
/*  overflow: hidden;*/
/*  z-index: 0;*/
/*}*/
/*.heading .logos, .heading .subtitle, .foto, img, .datos{*/
/*  position: absolute;*/
/*}*/

/*.heading .logos {*/
/*  letter-spacing: -0.4mm;*/
/*  top: -0.7cm;*/
/*  left: 0.3cm;*/
/*  font-size: 1cm;*/
/*  color: rgba(255,255,255,1);*/
/*  text-shadow: 0 1px 3px rgb(0 0 0 / 40%);*/
/*}*/

/*.datos, .heading .subtitle{*/
/*  list-style: none;*/
/*}*/

/*.datos{*/
/*  color: #fff;*/
/*  bottom: 0.2cm;*/
/*  font-size: 13px;*/
/*  font-weight: 600;*/
/*  left: -0.78cm;*/
/*  letter-spacing: 0.1mm;*/
/*  font-family: monospace;*/
/*  text-shadow: 0 1px 3px rgb(0 0 0 / 40%);*/
/*}*/

/*.heading .subtitle{*/
/*  font-size: 2.6mm;*/
/*  left: 2cm;*/
/*  line-height: 3mm;*/
/*  font-weight: 600;*/
/*  color: #009cc3;*/
/*}*/

/*.foto{*/
/*  height: 5.6cm;*/
/*  width: 5.6cm;*/
/*  right: -105px;*/
/*  top: -3px;*/
/*  opacity: 0.9;*/
/*  background: url(assets/image/logo-taxis.png);*/
/*  background-size: contain;*/
/*  background-repeat: no-repeat;*/
/*}*/
.error-detail{
  color: #E74C3C;
  padding-left: 0;
  font-size: 13px;
  top: -15px;
  position: relative;
}
.error{
  color: #d32f2f !important;
  background: rgb(211 47 47 / 20%) !important;
}
.cont-info-pagar{
  padding: 15px;
  display: grid;
  grid-template-columns: 60% 40%;;
}
.card-grid{
  box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
  border-radius: 8px;
  border-top: 6px solid #2f98ff;
}
.item-taxista{
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 8px;
  /*margin-bottom: 15px;*/
}
.pd-15{padding: 15px;}
.b-bottom, .b-bottom-final{
  border-bottom: 1px solid rgba(0,0,0,0.1);
  padding: 15px 0;
}
.b-bottom-final{border-bottom: 0 !important;}
.bold{font-weight: bold}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.class-row-commission{
 background: #80e8e0;
}
.class-row-seller{
  background: #ffe8bc;
}
.class-row-neutral{
  background: #c4c4c4;
}
.email-button{
  background:#7e57c6
}
.btnAddUser{
  width: 180px;
  height: 56px;
  margin-left: 15px !important;
  /*background-color: #673ab7 !important;*/
}


